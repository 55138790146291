<template>
  <header class="header">
    <!-- <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>美敦宜医疗</title> -->
    <el-row class="menu-header01">
      <el-col :span="2" :offset="3" class="logo-container">
        <router-link to="/">
          <el-image style="width: 40px; height: 40px; margin: 5px;" :size="50" :src="squareUrl">
          </el-image>
        </router-link>
      </el-col>
      <el-col :span="16" class="menu-container">
        <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <!-- <el-menu-item v-for="(item, index) in menuItems" :key="index" :index="item.link" :router="true">
            {{ item.text }}
          </el-menu-item> -->
          
          <el-menu-item index="1" :index="'/'">首页</el-menu-item>
          <el-menu-item index="2" :index="'/about'">关于我们</el-menu-item>
          <el-submenu index="3">
            <template slot="title">产品中心</template>
            <el-menu-item index="3-1" :index="'/products/01'">星超皮秒</el-menu-item>
            <el-menu-item index="3-2" :index="'/products/02'">超星炮</el-menu-item>
          </el-submenu>
          <el-menu-item index="4" :index="'/service'">客户服务</el-menu-item>
          <el-menu-item index="5" :index="'/brand'">品牌故事</el-menu-item>
          <el-menu-item index="6" :index="'/notification/list'">最新动态</el-menu-item>
          <!-- <el-submenu index="2">
            <template slot="title"> {{ item.text }}</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
          </el-submenu> -->

        </el-menu>
      </el-col>
    </el-row>
    <el-row class="menu-header02">
      <el-col :span="24" class="menu-container">

        <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <!-- <el-menu-item v-for="(item, index) in menuItems" :key="index" :index="item.link" :router="true">
            {{ item.text }}
          </el-menu-item> -->

          <el-menu-item index="1" :index="'/'">首页</el-menu-item>
          <el-menu-item index="2" :index="'/about'">关于我们</el-menu-item>
          <el-submenu index="3">
            <template slot="title">产品中心</template>
            <el-menu-item index="3-1" :index="'/products/01'">星超皮秒</el-menu-item>
            <el-menu-item index="3-2" :index="'/products/02'">超星炮</el-menu-item>
          </el-submenu>
          <el-menu-item index="4" :index="'/service'">客户服务</el-menu-item>
          <el-menu-item index="5" :index="'/brand'">品牌故事</el-menu-item>
          <el-menu-item index="6" :index="'/notification/list'">最新动态</el-menu-item>

        </el-menu>
      </el-col>
    </el-row>
  </header>
</template>

<script>
//   { text: '联系我们', link: '/contact' },
export default {
  data() {
    return {
      squareUrl: require("@/assets/img/web_logo.jpg"),
      menuItems: [
        { text: '首页', link: '/' },
        { text: '关于我们', link: '/about' },
        { text: '产品中心', link: '/products' },
        { text: '客户服务', link: '/service' },
        { text: '品牌故事', link: '/brand' },
        { text: '最新公告', link: '/notification/list' },
      ],
      hoveredIndex: null,
    };
  },
  mounted() {
    this.updateDocumentTitle();
  },
  watch: {
    '$route': 'updateDocumentTitle'
  },
  methods: {
    handleSelect(key, keyPath) {
      if (key !== this.$route.path) {
        this.$router.push(key);
      }
    },
    onMouseEnter(index) {
      this.hoveredIndex = index;
    },
    onMouseLeave() {
      this.hoveredIndex = null;
    },
    updateDocumentTitle() {
      const routeTitle = this.$route.meta && this.$route.meta.title;
      document.title = routeTitle ? routeTitle : '美敦宜医疗';
    }
  }
};
</script>

<style scoped>
.header {
  padding: 0px;
  background-color: #fff;
  height: 60px;
}

.menu-header01 {
  margin-left: 10%;
  width: 80%;
  display: flex;
  height: 60px;
}

.menu-header02 {
  width: 100%;
  display: none;
  height: 60px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menu-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


/* 响应式样式 */
@media (max-width: 768px) {
  .menu-header01 {
    width: 100%;
    display: none;
    height: 60px;
  }

  .menu-header02 {
    width: 100%;
    display: block;
    height: 60px;
  }
}
</style>