<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeIndex: '/'
    };
  }
}
</script>

<style>
/* html {
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f30;
  min-height: 100%;
  height: 100%;
}

body{
  margin: 0px;
  min-height: 100%;
  background-color: #f90;
} */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  body {
    display: flex; 
    flex-direction: column; 
    min-height: 100vh; 
  }
  .main-content {
    flex: 1; 
    background-color: #f9f9f9;
    padding: 20px;
  }
  .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
  }
</style>
