import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: '首页' },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: '关于我们' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/products/01',
    name: 'products01',
    meta: { title: '星超皮秒' },
    component: () => import('../views/ProductsView.vue')
  },
  {
    path: '/products/02',
    name: 'products02',
    meta: { title: '超星炮' },
    component: () => import('../views/ProductsView2.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { title: '联系我们' },
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: { title: '客户服务' },
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/brand',
    name: 'brand',
    meta: { title: '品牌故事' },
    component: () => import('../views/BrandView.vue')
  },
  {
    path: '/notification/list',
    name: 'NotificationList',
    meta: { title: '公告列表' },
    component: () => import('../views/NotificationListView.vue')
  },
  {
    path: '/notification/detail',
    name: 'NotificationDetail',
    meta: { title: '公告详情' },
    component: () => import('../views/NotificationDetailView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
